
const getDate = (currentDate) => {
    const date = (currentDate.getDate())
    if (date < 10) { return '0' + date }
    return date
  }

  const getMonth = (currentDate) => {
    const month = (currentDate.getMonth() + 1)
    if (month < 10) { return '0' + month }
    return month
  }

export function formatDateToLocalTime (date, format) {
   
    if (date === null || date === undefined) return '-'
    let currentDate
    // if (typeof date === 'string') { currentDate = new Date(date) } else if (typeof date === 'number') {
    //     if (format === 'FULL_DATE' || format === 'FULL_DATE_UTC') { currentDate = new Date(date * 1000) } else { currentDate = new Date(date * 1000 - (7 * 60 * 60 * 1000)) }
    //   } else { currentDate = date }
    //   if (!format) { return currentDate }

    if (typeof date === 'string') { currentDate = new Date(date) } else { currentDate = date }
    const dateString = currentDate.getFullYear().toString() + '-' + (currentDate.getMonth() + 1).toString().padStart(2, '0') + '-' + currentDate.getDate().toString().padStart(2, '0')
    // let dateString = '2022-02-09'
    const timeString = String(currentDate.getHours()).padStart(2, '0') + ':' + String(currentDate.getMinutes()).padStart(2, '0')
    const localDate = new Date(`${dateString}T${timeString}Z`)
    const currentHour = ('0' + localDate.getHours()).slice(-2)
    const currentMinute = ('0' + localDate.getMinutes()).slice(-2)
    
     
    // if (format === 'dd/mm/yyyy HH:MM') { return getDate(currentDate) + '/' + (getMonth(currentDate)) + '/' + currentDate.getFullYear() + ' ' + String(currentDate.getHours()).padStart(2, '0') + ':' + String(currentDate.getMinutes()).padStart(2, '0') }
    if (format === 'dd/mm/yyyy HH:MM') { return getDate(currentDate) + '/' + (getMonth(currentDate)) + '/' + currentDate.getFullYear() + ' ' + currentHour + ':' + currentMinute }
  
    return currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1).toString().padStart(2, '0') + '-' + currentDate.getDate().toString().padStart(2, '0') + ' ' + String(currentDate.getHours()).padStart(2, '0') + ':' + String(currentDate.getMinutes()).padStart(2, '0')

  }

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }


export const  checkEmailField = (value) => {
    const emails = value.split(',')
    let validEmail = false
    
    for (let i = 0; i < (emails ?? []).length; i++) {
      if (validateEmail(emails[i].trim())) {    
        validEmail = true
      } 
      else{
        validEmail = false
      }
    }
    // (emails ?? []).forEach(element => {
    //   if (validateEmail(element.trim())) {    
    //     validEmail = true
    //   } 
    //   else{
    //     validEmail = false
    //   }
    // })
    // const resultdata = validEmail;
    // return resultdata;

    return validEmail
   
} 
