import { notification } from 'antd'
import React, { lazy, Suspense } from 'react'
import './Scss/app.scss'
import {
    MemoryRouter as Router,
    Routes,
    Route
} from 'react-router-dom'
import axios from 'axios'
import './index.css';
import './style.css'
import 'antd/dist/antd.css';

import LoginPage from './Pages/LoginPages';

import FormHeaderPage from './Pages/FormHeaderPages';
import EntriesPage from './Pages/EntriesPages';
import EntriesTablePage from './Pages/EntriesTablePage';
import FormPage from './Pages/FormPage';


// const LoginPage = lazy(() => import('./Pages/LoginPages'))
// const FormHeaderPage = lazy(() => import('./Pages/FormHeaderPages'))
// const EntriesPage = lazy(() => import('./Pages/EntriesPages'))
// const FormPage = lazy(() => import('./Pages/FormPage'))


axios.interceptors.request.use(async (config) => {
    if (window.FormConfiguration["API_KEY"] !== null) config.headers['x-api-key'] = window.FormConfiguration["API_KEY"];
    //config.headers['x-api-key'] = 'aded8e91-6a5f-4c96-956d-a1be0d67f071'
    config.timeout = 30000;
    return config;
})

axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
      
        console.log(JSON.stringify(error))

        switch (error?.response?.status) {
            case 401:
                
                notification.error({ message: "Unauthorized (401)", description: "Invalid API Key" })
                return Promise.reject({ ...error })
            case 500:
                notification.error({ message: "Internal Server Error (500)", description: "Oops something is wrong with the server" })
                return Promise.reject({ ...error })
            default: 
                console.log('error', error)
                if (error?.response?.data?.success === false)
                {                   
                    notification.error({ message: error?.response?.data?.errors.join(',') })               
                }
                else{
                    notification.error({ description: error.message })
                }
                break;
        }

       
        return Promise.reject({ ...error })
    }
)



const App = () => (
    <>
        <Router>
            <Routes>
                <Route path='/login' element={<LoginPage />} />
                <Route path='/' element={<Suspense fallback={<>...</>}><FormHeaderPage /></Suspense>} />
                <Route path='/entrieslist/:id' element={<Suspense fallback={<>...</>}><EntriesPage /></Suspense>} />
                <Route path='/entriestable/:id' element={<Suspense fallback={<>...</>}><EntriesTablePage /></Suspense>} />

                <Route path='/formlist' element={<Suspense fallback={<>...</>}><FormHeaderPage /></Suspense>} />
                <Route path='/form' element={<Suspense fallback={<>...</>}><FormPage /></Suspense>} />
                <Route path='/form/:id' element={<Suspense fallback={<>...</>}><FormPage /></Suspense>} />
            </Routes>
        </Router>
    </>
)

export default App
