
import React, { useEffect, useState } from 'react';
import { Space, Table, Tag, Button, Modal, notification, Card, Layout, Input, DatePicker } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { FORMAT_DATE_TIME_API, FORMAT_DATE_TIME_SHOW, PAGINATION_SIZE } from '../Utils/Constans';
import { deleteEntry, getEntriesForm } from '../Services/DataService';
import { formatDateToLocalTime } from '../Utils/StringUtils';
import { ArrowLeftOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import EntriesListContainer from '../Containers/EntriesListContainer';
import EntryDetailsContainer from '../Containers/Entries/EntryDetailsContainer';
import moment from 'moment';

const { Content } = Layout;
const { confirm } = Modal;
const { RangePicker } = DatePicker;

const EntriesPages = () => {
  const [data, setData] = useState([])
  const [dataColumn, setDataColumn] = useState([])
  const [pageSize, setPageSize] = useState(PAGINATION_SIZE)
  const [totalData, setTotalData] = useState(0)
  const [showDetail, setShowDetail] = useState(false)
  const [dataDetails, setDataDetails] = useState([])
  const [headerData, setHeaderData] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [loadingSearch, setLoadingSearch] = useState(false)

  const [startDate, setStartDate] = useState()
  const [endDate, setEndtDate] = useState()

  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    fetchEntriesData(1, pageSize, '')
  }, [id])

  useEffect(() => { onClickSearchValue() }, [startDate, endDate]);

  const fetchEntriesData = async (page, size, searchValue) => {

    setLoadingSearch(true)
    try {
      var param = {
        page: page,
        size: size,
        searchValue: searchValue,
        formId: id,
        startDate: startDate,
        endDate: endDate
      }

      const resp = await getEntriesForm(param)

      if (resp?.data?.success) {
        resp?.data?.payload?.items.forEach(item => {
          item.key = item.id
        })


        setData(resp?.data?.payload?.items)
        setTotalData(resp?.data?.payload?.totalItems)

        resp?.data?.payload?.items.forEach(element => {
          var contentData = JSON.parse(element.content)
          sourceColumn(contentData)


        })

      }

    } catch (e) {
      notification.error({ message: e.message })
      console.log('error', e)
    }
    setLoadingSearch(false)
  }

  const onChangePage = (page, size) => {
    setPageSize(size)
    fetchEntriesData(page, size, '');
  };

  const onClickShowDetails = (record) => {

    var jsonData = JSON.parse(record.content)

    let arrData = []

    for (var i in jsonData) {
      const val = jsonData[i]
      arrData.push({
        key: i,
        value: typeof(val) === 'object' ? JSON.stringify(val) : val,
      })
    }

    setHeaderData(record)

    setDataDetails(arrData)

    setShowDetail(!showDetail)
  };

  const onCloseDetails = () => {

    setShowDetail(!showDetail)
  };

  const sourceColumn = (contentData) => {

    setDataColumn([])
    let arrData = []

    arrData.push({
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
      render: (text) => <>{text}</>,
    })

    arrData.push({
      title: 'Submission date',
      dataIndex: 'creationTime',
      key: 'creationTime',
      render: (text) => <>{formatDateToLocalTime(text, FORMAT_DATE_TIME_SHOW)}</>,
    })

    arrData.push({
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => onClickShowDetails(record)} >Details</Button>
          <Button onClick={() => showDeleteConfirm(record.id)}>Delete</Button>
        </Space>
      ),
    })

    setDataColumn(arrData)
  }

  const onDeleteEntry = async (id) => {
    try {
      const resp = await deleteEntry(id);

      if (resp?.data?.success) {
        notification.success({ message: 'dataDeletedNotification' })
        fetchEntriesData(1, pageSize, '');
      } else {

        notification.error({ message: (resp?.data?.errors ?? []).join(' \n') })
      }
    } catch (e) { }
  }

  const showDeleteConfirm = (entryId) => {
    confirm({
      title: 'Delete Entry',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure delete this entry ?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {

        onDeleteEntry(entryId)
      },
      onCancel() {
        console.log('Cancel')
      },
    });
  };

  const onChangeSearchValue = (value) => { setSearchValue(value.target.value) }

  const onClickSearchValue = () => {
    fetchEntriesData(1, pageSize, searchValue);
  }

  const onChangeDateRangeValue = (value) => {
    if (value !== null) {
      const valueOfInput1 = moment(value[0]).format(FORMAT_DATE_TIME_API);
      const valueOfInput2 = moment(value[1]).format(FORMAT_DATE_TIME_API);
      setStartDate(valueOfInput1)
      setEndtDate(valueOfInput2)
    }
    else {
      setStartDate(null)
      setEndtDate(null)
    }
  }


  return (
    <Layout>
      <Content className='site-layout-content' style={{ padding: '50px' }}>
        <Card size="small" title="Entries" extra={<Button onClick={() => navigate("/formlist")}><ArrowLeftOutlined /></Button>}>

          {!showDetail &&
            <Space size="middle">
              <RangePicker format={FORMAT_DATE_TIME_API} onChange={onChangeDateRangeValue} />
              <Input.Search
                // allowClear
                // style={{
                //   width: '20%',
                // }}
                value={searchValue}
                onChange={onChangeSearchValue}
                loading={loadingSearch}
                onSearch={() => onClickSearchValue()}

              />
            </Space>
          }

          {!showDetail &&
            <EntriesListContainer
              dataSource={data}
              dataColumn={dataColumn}
              pageSize={pageSize}
              totalData={totalData}
              onChangePage={onChangePage}
            />
          }
        </Card>

        {showDetail &&
          <EntryDetailsContainer
            onClose={onCloseDetails}
            sourceData={dataDetails}
            headerData={headerData}
          />
        }

      </Content>
    </Layout>

  );
};

export default EntriesPages;