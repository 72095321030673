import { Button, Drawer, Space, Form,Switch,Input } from 'antd';
import React, { useEffect, useState } from 'react'
import InputTinyMce from '../../Components/InputTinyMce';

const FormCustomFlowContainer = ({OnCloseDrawerWorkFlow, drawerWorkflow, activeDomain,
    formCustomWorkflow, setFormCustomWorkflow,initDataWorkFlow}) => {
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [message, setMessage] = useState('');  


    //const [activeWorkFlow, setActiveWorkflow] = useState(null)

    useEffect(() => {
        if (activeDomain === '') return

        let data = formCustomWorkflow.filter(x => x.domainId === activeDomain).pop()
        if (data === undefined)
        {
            data = initDataWorkFlow;
            data.domainId = activeDomain;
           
            setFormCustomWorkflow([...formCustomWorkflow, initDataWorkFlow])           
        }
        
        
        //setActiveWorkflow(data)

        form.setFieldValue('name', data?.name)
        form.setFieldValue('status', data?.status)
        form.setFieldValue('includeAttachment', data?.includeAttachment)
        form.setFieldValue('message', data?.message)
        form.setFieldValue('recipient', data?.recipient)
        form.setFieldValue('cc', data?.cc)
        form.setFieldValue('bcc', data?.bcc)
        form.setFieldValue('sender', data?.sender)
        form.setFieldValue('subject', data?.subject)
        form.setFieldValue('mailchimpApiKey', data?.mailchimpApiKey)
        form.setFieldValue('mailchimpAudienceId', data?.mailchimpAudienceId)

        setMessage(data?.message)

    }, [activeDomain])
  
    const onSubmitForm = (values) => {
      
        console.log(values)   
        values.domainId = activeDomain
             
        //add to array, if exist the update  formCustomWorkflow
        const i = formCustomWorkflow.findIndex(x => x.domainId === activeDomain)
        if (i > -1) {
            formCustomWorkflow[i] = values
            formCustomWorkflow[i]['message'] = message
        }
        else {
            formCustomWorkflow.push(values)
            formCustomWorkflow[0]['message'] = message
        }
        console.log('formCustomWorkflow', formCustomWorkflow)

        OnCloseDrawerWorkFlow()
    }

    const onChangeWorkFlow = (e, isCheckedValue = false, objName ='') => {
      
        const name = e?.target?.name;
        const value = e?.target?.value;
       
        //get currentarray
        let dataWorkFlow = formCustomWorkflow.filter(x => x.domainId === activeDomain).pop();
      
        if (isCheckedValue)
        {
            dataWorkFlow[objName] = e;
        }
        else{
            dataWorkFlow[name] = value;            
        }
      
        setFormCustomWorkflow([...formCustomWorkflow.filter(x => x.domainId !== activeDomain), dataWorkFlow])
      
    }
    const onChangeMessage = (e) => {
      
        setMessage(e)
        onChangeWorkFlow(e,true,'message')
      
    }

    return (

        <Drawer size="large" title="Custom Workflow" placement="right"
        onClose={OnCloseDrawerWorkFlow} visible={drawerWorkflow}
        extra={
            <Space>
                <Button form='formCustomWorkflow' type="primary" htmlType='submit'>Save</Button>
            </Space>
        } >
            <Form id='formCustomWorkflow' labelCol={{ span: 4, }} wrapperCol={{ span: 19, }} onFinish={onSubmitForm}
                    initialValues={
                        { includeAttachment: false,status:false }
                    } form={form}>
                               
                <Form.Item name="name" label="Name" >
                    <Input  onChange={(e) => onChangeWorkFlow(e)}  />
                </Form.Item>
                <Form.Item name="status" label="Status" valuePropName="checked">
                    <Switch onChange={(e) => onChangeWorkFlow(e, true,'status')} />
                </Form.Item>
                <Form.Item name="includeAttachment" label="Attachment" valuePropName="checked">
                    <Switch onChange={(e) => onChangeWorkFlow(e, true,'includeAttachment')}/>
                </Form.Item>
                <Form.Item name="subject" label="Subject">
                    <Input  onChange={(e) => onChangeWorkFlow(e)} />
                </Form.Item>
              
                  <Form.Item name="message" label="Message">
                  <InputTinyMce dataValue={message} onChangeData={onChangeMessage}   />
                </Form.Item>
             
                {/* <Form.Item name="recipient" label="Recipient" rules={[                       
                        () => ({
                            validator(_, value) {
                              
                                if (value === '') return Promise.resolve();

                                let validEmail = checkEmailField(value);
 
                                if (validEmail) return Promise.resolve();
                                
                                return Promise.reject(new Error(NOT_VALID_EMAIL));
                                //return Promise.resolve();
                                //return Promise.reject(new Error(NOT_VALID_EMAIL));
                            },
                          })
                    ]}>
                    <Input onChange={(e) => onChangeWorkFlow(e)}  />
                </Form.Item> */}
                 <Form.Item name="recipient" label="Recipient" >
                    <Input onChange={(e) => onChangeWorkFlow(e)}  />
                </Form.Item>

                <Form.Item name="cc" label="CC" >
                    <Input onChange={(e) => onChangeWorkFlow(e)} />
                </Form.Item>
                <Form.Item name="bcc" label="BCC" >
                    <Input onChange={(e) => onChangeWorkFlow(e)} />
                </Form.Item>
                <Form.Item name="sender" label="Sender" >
                    <Input onChange={(e) => onChangeWorkFlow(e)}  />
                </Form.Item>
                <Form.Item name="mailchimpApiKey" label="MailChimp ApiKey" >
                    <Input onChange={(e) => onChangeWorkFlow(e)}  />
                </Form.Item>
                <Form.Item name="mailchimpAudienceId" label="MailChimp AudienceId" >
                    <Input onChange={(e) => onChangeWorkFlow(e)}  />
                </Form.Item>
            </Form>
             
            
        </Drawer>
        
    )
}

export default FormCustomFlowContainer