import React from 'react';
import InputButton from '../../Components/InputButton';
import InputField from '../../Components/InputField';

const LoginContainer = () => {
    return (
        <div>
            <InputField />
            <InputButton/>
        </div>
    );
};

export default LoginContainer;