export const PAGINATION_SIZE = 20
export const MESSAGE_REQUIRED = "Please input this field"
export const MESSAGE_SUCCESS_PROCESSED = "Success save the data"
export const LIST_FORM_DETAILS_REQUIRED = "Form Details cannot be empty"
export const NOT_VALID_EMAIL = "Not valid email"

export const INPUT_TYPES = [{
    key: "textfield",
    value: "Text Field"
},
{
    key: "textarea",
    value: "Text Area"
},
{
    key: "dropdown",
    value: "Dropdown"
},
{
    key: "dropdownkeyvalue",
    value: "Dropdown Key Value"
},
{
    key: "checkbox",
    value: "Checkbox"
},
{
    key: "checkboxmultiple",
    value: "Checkbox Multiple"
},
{
    key: "checkboxmultiplekeyvalue",
    value: "Checkbox Multiple Key Value"
},
{
    key: "uploadfile",
    value: "Upload File"
}, {
    key: "captcha",
    value: "Captcha"
},
{
    key: "hidden",
    value: "Hidden"
}]

export const REDIRECT_TYPES = [
    {
        key: "url",
        value: "URL"
    },
    {
        key: "message",
        value: "Message"
    }]

export const FORMAT_DATE_TIME_SHOW = "dd/mm/yyyy HH:MM"
export const FORMAT_DATE_TIME_API = "YYYY-MM-DD"

export const COLUMN_TYPES = [{
    key: "1",
    value: "1"
},
{
    key: "2",
    value: "2"
},
{
    key: "3",
    value: "3"
},
{
    key: "4",
    value: "4"
},
{
    key: "5",
    value: "5"
},
{
    key: "6",
    value: "6"
},
{
    key: "7",
    value: "7"
},
{
    key: "8",
    value: "8"
},
{
    key: "9",
    value: "9"
},
{
    key: "10",
    value: "10"
},
{
    key: "11",
    value: "11"
},
{
    key: "12",
    value: "12"
},
]

// export const COLUMN_TYPES = () =>{
//     let data = [{}]
//     for (let i = 1; i <= 12 - 1; i++) {
//         data.push({key: i, value: i})
//       }

//       return data;
// }