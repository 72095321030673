import { Button, Space, Table } from 'antd';
import React, { useState } from 'react';
import { DeleteOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons';

const FormDetailListContainer = ({ formDetailList, setFormDetailList, setDrawerForm,onSelectedDataDetail }) => {

    const onCopyRecord = (item) => {
        const lastIndex = parseInt(formDetailList?.length) + 1;
        var newData = { ...item }
        newData['placeholder'] = `${newData['placeholder']} ${lastIndex}`;
        newData['key'] = Math.random();
        setFormDetailList([...formDetailList, newData])
    }

    const onDeleteRecord = (item) => {
        const data = formDetailList.filter(x => x.key !== item?.key)
        setFormDetailList([...data])
    }

    const onEditData = (record) => {
        onSelectedDataDetail(record)
        setDrawerForm(true)
    }
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {     
        setSortedInfo(sorter);
      };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'placeholder',
            key: 'placeholder',
        },
        {
            title: 'Type',
            dataIndex: 'inputType',
            key: 'inputType',
        },
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'order',
            sorter: (a, b) => a.order - b.order,
            sortOrder: sortedInfo.columnKey === 'order' ? sortedInfo.order : null,
        },
        {
            title: 'Column',
            dataIndex: 'columnType',
            key: 'Column',
           
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="small">
                    <Button onClick={() => onEditData(record)} icon={<EditOutlined />} />
                    <Button onClick={() => onDeleteRecord(record)} icon={<DeleteOutlined />} />
                    <Button onClick={() => onCopyRecord(record)} icon={<CopyOutlined />} />
                </Space>
            ),
            width: '10%',
        }
    ];

    return (
        <Table dataSource={formDetailList} columns={columns} pagination={false} onChange={handleChange}/>
    )
}

export default FormDetailListContainer