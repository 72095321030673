import { Form, Button, Select, Input } from 'antd'
import React, { useEffect } from 'react'
import { MESSAGE_REQUIRED } from '../../Utils/Constans';

const { Option } = Select;
const FormHeaderContainer = ({ onSubmitForm, handleChangeDomain, domainList, currentSelectedData, isEditMode,setSelectedDomain }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (isEditMode && currentSelectedData) {
            const selectedDomains = currentSelectedData?.formDomainsFull;
            const arrDomain = selectedDomains.map((item) => {return item?.domainId})

            form.setFieldValue('name', currentSelectedData?.name)
            form.setFieldValue('submitButton', currentSelectedData?.submitButton)
            form.setFieldValue('domainName', arrDomain)
            setSelectedDomain(arrDomain)
            
        }
    }, [isEditMode, currentSelectedData,form,setSelectedDomain]);

    return (
        <Form
            form={form}
            name="basic"
            labelCol={{
                span: 4,
            }}
            wrapperCol={{
                span: 19,
            }}
            onFinish={onSubmitForm}
            onFinishFailed={() => { }}
            autoComplete="off"
        >
            <Form.Item
                label="Form Name"
                name="name"
                rules={[
                    {
                        required: true,
                        message: MESSAGE_REQUIRED,
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Domain"
                name="domainName"
                rules={[
                    {
                        required: true,
                        message: MESSAGE_REQUIRED,
                    },
                ]}
            >
                <Select
                    mode="multiple"
                    onChange={handleChangeDomain}
                >{
                        (domainList ?? []).map((item) => {
                            return (
                                <Option key={item?.key}>{item?.value}</Option>
                            )
                        })
                    }

                </Select>
            </Form.Item>

            <Form.Item
                label="Submit Button"
                name="submitButton"
                rules={[
                    {
                        required: true,
                        message: MESSAGE_REQUIRED,
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 2,
                    span: 10,
                }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default FormHeaderContainer