import React from 'react';

const InputField = () => {
    return (
        <div>
            Input Field
        </div>
    );
};

export default InputField;