import React, { useEffect, useState } from 'react';
import { Table, Layout } from 'antd';
import EmptyWithMessage from '../Components/EmptyWithMessage';

const { Content } = Layout;

const EntriesListContainer = ({ dataSource, dataColumn, pageSize, totalData, onChangePage }) => {
  return (
    <Table
      locale={{
        emptyText: <EmptyWithMessage />
      }}
      columns={dataColumn}
      dataSource={dataSource}
      pagination={{
        defaultPageSize: pageSize,
        total: totalData,
        onChange: onChangePage
      }}
    />

  );
};

export default EntriesListContainer;