import React from 'react';
import LoginContainer from '../Containers/Login/LoginContainer';

const LoginPages = () => {
    return (
        <div>
            <LoginContainer />
        </div>
    );
};

export default LoginPages;