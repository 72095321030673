import { Form, Input, Radio, Tabs, Switch, Space, Col } from 'antd'
import { Button } from 'antd/lib/radio';
import React, { useEffect, useState } from 'react'
import { MESSAGE_REQUIRED, REDIRECT_TYPES } from '../../Utils/Constans';
import FormCustomFlowContainer from './FormCustomFlowContainer';

const { TabPane } = Tabs;
const FormDomainContainer = ({ selectedDomains, formDomainData, setFormDomainData, domainList,
  currentSelectedData, isEditMode, formCustomWorkflow, setFormCustomWorkflow, initDataWorkFlow }) => {

  const [customFlow, setCustomFlow] = useState(false)
  const [drawerWorkflow, setDrawerWorkflow] = useState(false)
  const [activeDomain, setActiveDomain] = useState('')

  useEffect(() => {

    (selectedDomains ?? []).map((item, index) => {
      const existdata = formDomainData.filter(x => x.domainId === item)
      if (existdata.length <= 0) {
        setFormDomainData([...formDomainData, { domainId: item, redirectType: 'message', isOpenNewTab: false }])
      } else {
        const existdata = formDomainData.filter(x => selectedDomains.includes(x.domainId))
        setFormDomainData(existdata)
      }
    })
  }, [selectedDomains])

  useEffect(() => {
    if (isEditMode && currentSelectedData) {
      const data = currentSelectedData?.formDomainsFull;
      setFormDomainData(data)

      const customWorkflow = currentSelectedData?.formCustomFlows
      setFormCustomWorkflow(customWorkflow)
    }
  }, [isEditMode, currentSelectedData]);

  const onChangeFormDomain = (e, domainId, isCustomWorkflow = false) => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    let dataDomainId = formDomainData.filter(x => x.domainId === domainId).pop();

    if (isCustomWorkflow) {
      dataDomainId["isCustomWorkflow"] = e;
    }
    else {
      dataDomainId[name] = value;
    }
    setFormDomainData([...formDomainData.filter(x => x.domainId !== domainId), dataDomainId])
  }

  const OnCloseDrawerWorkFlow = () => {
    setDrawerWorkflow(false)
  }

  const onShowCustomFlow = (item) => {
    setActiveDomain(item)
    setDrawerWorkflow(true)
  };

  return (
    <>
      <Tabs defaultActiveKey="1" tabPosition='top'>
        {
          (selectedDomains ?? []).map((item, index) => {

            const formData = formDomainData.filter(x => x.domainId === item).pop()
            const selectedTitle = domainList.filter(x => x.key === item).pop()?.value;
            return (
              <TabPane tab={selectedTitle} key={item}  >
                <Col span={12}>
                  <Form>
                    <Form.Item label="Redirect Type">
                      <Radio.Group defaultValue={formData?.redirectType} name='redirectType' onChange={(e) => onChangeFormDomain(e, item)}>
                        {
                          REDIRECT_TYPES.map((item) => <Radio.Button key={item.key} value={item.key}>{item.value}</Radio.Button>)
                        }
                      </Radio.Group>
                    </Form.Item>
                    {formData?.redirectType === 'url' && <>
                      <Form.Item label="Open New Tab">
                        <Radio.Group defaultValue={formData?.isOpenNewTab} name='isOpenNewTab' onChange={(e) => onChangeFormDomain(e, item)}>
                          <Radio.Button key="false" value={false}>No</Radio.Button>
                          <Radio.Button key="true" value={true}>Yes</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item required label="Url">
                        <Input value={formData?.url} onChange={(e) => onChangeFormDomain(e, item)} name="url" id='url' rules={[
                          {
                            required: true,
                            message: MESSAGE_REQUIRED,
                          },
                        ]} />
                      </Form.Item>
                    </>
                    }
                    {formData?.redirectType === 'message' &&
                      <Form.Item required label="Message">
                        <Input.TextArea value={formData?.message} onChange={(e) => onChangeFormDomain(e, item)} name="message" id='message' rules={[
                          {
                            required: true,
                            message: MESSAGE_REQUIRED,
                          },
                        ]} />
                      </Form.Item>
                    }
                    <Form.Item label="CustomWorkflow" >

                      <Space>
                        <Switch name="isCustomWorkflow" defaultChecked={formData?.isCustomWorkflow} onChange={(e) => onChangeFormDomain(e, item, true)} />
                        <Button onClick={() => onShowCustomFlow(item)} type="primary" disabled={!formData?.isCustomWorkflow}>Edit</Button>
                      </Space>

                    </Form.Item>

                  </Form>
                </Col>
              </TabPane>
            )
          })
        }
      </Tabs>
      <FormCustomFlowContainer
        OnCloseDrawerWorkFlow={OnCloseDrawerWorkFlow}
        drawerWorkflow={drawerWorkflow}
        activeDomain={activeDomain}
        formCustomWorkflow={formCustomWorkflow}
        setFormCustomWorkflow={setFormCustomWorkflow}
        initDataWorkFlow={initDataWorkFlow}
      />
    </>
  )
}

export default FormDomainContainer