import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Layout, notification, Row } from 'antd';
import { FormDetailContainer, FormDomainContainer, FormHeaderContainer } from '../Containers';
import { LIST_FORM_DETAILS_REQUIRED, MESSAGE_SUCCESS_PROCESSED } from '../Utils/Constans';
import { createForm, getFormById, updateForm } from '../Services/FormService';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getDomains } from '../Services/ClientService';

const { Content } = Layout;
const FormPage = () => {
    const [selectedDomain, setSelectedDomain] = useState([])
    const [formDetailList, setFormDetailList] = useState([])
    const [formDomainData, setFormDomainData] = useState([])
    const [formCustomWorkflow, setFormCustomWorkflow] = useState([])

    const [currentSelectedData, setCurrentSelectedData] = useState(null)
    const [domainList, setDomainList] = useState([])
    const { id } = useParams()
    const navigate = useNavigate()
    const isEditMode = id !== undefined && id !== null && id !== ''

    const initDataWorkFlow = {
        name: '',
        status: false,
        includeAttachment: false,
        message: '',
        recipient: '',
        cc: '',
        bcc: '',
        sender: '',
        domainId: '',
        subject: '',
        mailchimpApiKey: '',
        mailchimpAudienceId: ''
    }

    useEffect(() => {
        if (isEditMode) {
            getFormById(id).then((res) => {
                const data = res?.data;
                if (data.success) {
                    setCurrentSelectedData(data?.payload)
                    setFormDetailList(data?.payload?.formDetails)
                }
            });
        } else setCurrentSelectedData(null)
    }, [id, isEditMode])

    useEffect(() => {
        getDomains().then(res => {
            const dataRes = res.data;
            if (dataRes.success) {
                const data = dataRes.payload;
                let arrData = [];
                data.map((item) => arrData.push({ key: item?.id, value: item?.url }))
                setDomainList(arrData)
            }
        })
    }, [])

    const handleChangeDomain = (value) => {
        console.log('value', value)
        value.forEach(element => {


            const i = formCustomWorkflow.findIndex(x => x.domainId === element)
            if (i > -1) {


            }
            else {
                let data = initDataWorkFlow;
                data.domainId = element;

                setFormCustomWorkflow([...formCustomWorkflow, data])
            }


        });

        formCustomWorkflow.forEach(element => {
            const i = value.findIndex(x => x === element.domainId)
            if (i > -1) {


            }
            else {
                setFormCustomWorkflow([...formCustomWorkflow.filter(x => x.domainId !== element.domainId)])
            }


        });



        setSelectedDomain(value)


    };

    const onSubmitForm = (values) => {
        if (formDetailList.length <= 0) {
            openNotification(LIST_FORM_DETAILS_REQUIRED)
            return false
        }

        for (let index = 0; index < formDomainData.length; index++) {
            const item = formDomainData[index];
            const redirectType = item?.redirectType;
            const url = item?.url;
            const message = item?.message;
            if (redirectType === 'url') {
                if (url === null || url === undefined || url === '') {
                    openNotification("There is URL field Empty")
                    return true;
                }
            } else if (redirectType === 'message') {
                if (message === null || message === undefined || message === '') {
                    openNotification("There is Message field Empty")
                    return true
                }
            }

        }

        let dataPost = values;
        dataPost['domains'] = formDomainData
        dataPost['details'] = formDetailList
        dataPost['customWorkflows'] = formCustomWorkflow
        console.log(dataPost)
        if (isEditMode) {
            dataPost['id'] = currentSelectedData?.id
            updateFormData(dataPost)
        }
        else createFormData(dataPost)
    };

    const openNotification = (message) => {
        notification.error({
            message: 'Validation Error',
            description: message,
        });
    };

    const createFormData = async (data) => {
        try {
            const resp = await createForm(data);
            actionAfterSave(resp)
        } catch (e) { }
    }

    const updateFormData = async (data) => {
        try {
            const resp = await updateForm(data);
            actionAfterSave(resp)
        } catch (e) { }
    }

    const actionAfterSave = (resp) => {
        if (resp?.data?.success) {
            notification.success({ message: MESSAGE_SUCCESS_PROCESSED })
            navigate("/formlist")
        } else {
            notification.error({ message: (resp?.data?.errors ?? []).join(' \n') })
        }
    }

    return (
        <Layout>
            <Content className='site-layout-content' style={{ padding: '50px' }}>
                <Card size="small" title="Form" extra={<Button onClick={() => navigate("/formlist")}><ArrowLeftOutlined /></Button>}>
                    <Row>
                        <Col span={12}>
                            <FormHeaderContainer
                                domainList={domainList}
                                handleChangeDomain={handleChangeDomain}
                                onSubmitForm={onSubmitForm}
                                currentSelectedData={currentSelectedData}
                                isEditMode={isEditMode}
                                setSelectedDomain={setSelectedDomain}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormDomainContainer
                                domainList={domainList}
                                selectedDomains={selectedDomain}
                                formDomainData={formDomainData}
                                setFormDomainData={setFormDomainData}
                                currentSelectedData={currentSelectedData}
                                isEditMode={isEditMode}
                                formCustomWorkflow={formCustomWorkflow}
                                setFormCustomWorkflow={setFormCustomWorkflow}
                                initDataWorkFlow={initDataWorkFlow}
                            />
                        </Col>
                    </Row>
                </Card>
                <FormDetailContainer formDetailList={formDetailList} setFormDetailList={setFormDetailList} />
            </Content>
        </Layout>
    )
}

export default FormPage