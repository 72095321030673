import React, { useEffect,useState } from 'react';
import { Space, Table, Tag , Button,Modal, notification  } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { deleteFormHeader, getFormHeader } from '../Services/FormService';
import { formatDateToLocalTime } from '../Utils/StringUtils';
import { FORMAT_DATE_TIME_SHOW, PAGINATION_SIZE } from '../Utils/Constans';
import { useNavigate } from 'react-router-dom';


const { confirm } = Modal;

const ListFormHeaderContainer = () => {

    const [data, setData] = useState([])
    const [pageSize, setPageSize] = useState(PAGINATION_SIZE)  
    const [totalData, setTotalData] = useState(0)
    const navigate = useNavigate();

    useEffect(() => {   
        fetchHeaderData(1,pageSize)
    }, [])

      const fetchHeaderData = async (page, size) => {
      
        try {
          var param = {
            page : page,
            size : size
          }
        
        
            const resp = await getFormHeader(param)

            resp?.data?.payload?.items.forEach(item => {
                item.key = item.id
            })
            if (resp?.data?.success)
            {
              setData(resp?.data?.payload?.items)
              setTotalData(resp?.data?.payload?.totalItems)
            
            }
          
          
        } catch (e) {
          notification.error({ message: e.message })
          console.log('error',e)
        }
    
    }

    const onChangePage = (page, size) => { 
      setPageSize(size)
      fetchHeaderData(page,size)
    };



      const onDeleteFormHeader = async (id) => {
        try {
            const resp = await deleteFormHeader(id);
           
            if (resp?.data?.success) {              
                notification.success({ message: 'dataDeletedNotification' })
                fetchHeaderData(1, pageSize);
            } else {
             
                notification.error({message: (resp?.data?.errors ?? []).join(' \n') })
            }
        } catch (e) { }
    }

    const showDeleteConfirm = (name, formId) => {
        confirm({
          title: 'Delete Form',
          icon: <ExclamationCircleOutlined />,
          content: `Are you sure delete this form ${name} ?`,
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
           
            onDeleteFormHeader(formId)
          },
          onCancel() {
            console.log('Cancel')
          },
        });
      };

    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (text) => <>{text}</>,
          defaultSortOrder: 'ascend',
        },
        {
          title: 'Submit Button',
          dataIndex: 'submitButton',
          key: 'submitButton',
          render: (text) => <>{text}</>
        },
        {
          title: 'Created',
          dataIndex: 'creationTime',
          key: 'creationTime',
          render: (text) => <>{formatDateToLocalTime(text,FORMAT_DATE_TIME_SHOW)}</>,
          
        },
        {
          title: 'Last Updated',
          dataIndex: 'lastModificationTime',
          key: 'lastModificationTime',
          render: (text) => <>{formatDateToLocalTime(text,FORMAT_DATE_TIME_SHOW )}</>,
        },
        {
          title: 'Domain',
          key: 'formDomains',
          dataIndex: 'formDomains',
          render: (_, { formDomains }) => (
            <>
            
              { formDomains.map((tag) => {
                let color = tag.length > 5 ? 'geekblue' : 'green';
      
                if (tag === 'localhost') {
                  color = 'volcano';
                }
      
                return (
                  <Tag color={color} key={tag}>
                    {tag}
                  </Tag>
                );
              })}
            </>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              {/* <Popover                
                title="View Details"
                trigger="click"
                content={contentView}
                
                >
                <a>View</a>
                </Popover> */}
              <Button onClick={ () => navigate(`/form/${record.id}`)}>Edit</Button>
              {/* <Button onClick={ () => navigate(`/entrieslist/${record.id}`)}>Entries</Button> */}
              <Button onClick={ () => navigate(`/entriestable/${record.id}`)}>Entries Table</Button>
              {/* <a>Delete</a> */}
              <Button onClick={ () => showDeleteConfirm(record.name, record.id)}>Delete</Button>
            </Space>
          ),
        },
      ];
    

    return (
        <div>
            
            <h1>Forms</h1>
            
            <Button type="primary" onClick={() => navigate("/form")}>New</Button>

            <Table columns={columns}  
                    dataSource={data}
                    pagination={{defaultPageSize:pageSize, 
                      total:totalData,
                      onChange: onChangePage
                    }}
                   />

           
        </div>
    );
};

export default ListFormHeaderContainer;