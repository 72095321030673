import React, { useEffect,useState } from 'react';
import { Col, Row , Button,Modal, notification, Card,  Layout, List  } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { formatDateToLocalTime } from '../../Utils/StringUtils';
import { FORMAT_DATE_TIME_SHOW } from '../../Utils/Constans';

const EntryDetailsContainer = ({onClose, sourceData, headerData}) => {
   
   
      const dataHeaderShow = [
        {
          key: 'Submission date',
          value: formatDateToLocalTime(headerData?.creationTime, FORMAT_DATE_TIME_SHOW)
        },        
        {
            key: 'Form',
          value: headerData?.formName
        },
        {
            key: 'Domain',
          value: headerData?.domainUrl
        },
      ];
    return (
      <>
                <div className="site-card-wrapper">
                    <Card size="small" title="Details" extra={<Button onClick={() => onClose()}><CloseCircleOutlined /></Button>} >
                        <Row gutter={16}> 
                            <Col span={16}>
                                <List 
                                    itemLayout="horizontal" 
                                    dataSource={sourceData}
                                    bordered
                                    renderItem={(item) => (
                                        <List.Item>
                                        <List.Item.Meta                           
                                            title={item?.key}
                                            description={item?.value}
                                        />
                                        </List.Item>
                                    )}

                                />
                            </Col>
                            
                            <Col span={4}>
                                {/* <p>creationTime : {headerData.creationTime}</p>
                                <p>lastModificationTime : {headerData.lastModificationTime}</p>
                                <p>form : {headerData.formHeaderId}</p> */}
                                 <List 
                                    itemLayout="horizontal" 
                                    dataSource={dataHeaderShow}
                                    bordered
                                    renderItem={(item) => (
                                        <List.Item>
                                        <List.Item.Meta                           
                                            title={item?.key}
                                            description={item?.value}
                                        />
                                        </List.Item>
                                    )}

                                />

                            </Col>    
                        
                            
                        </Row>
                    </Card>
                </div>
                
            
            
        </>
    );
};

export default EntryDetailsContainer;