import axios from "axios";

export const getEntriesForm = async (data) => {
    var sort = data.sort ? "&sort=" + data.sort : ""
    var searchValue = data.searchValue ? "&searchValue=" + data.searchValue : ""
    var startDate = data.startDate ? "&startDate=" + data.startDate : ""
    var endDate = data.endDate ? "&endDate=" + data.endDate : ""

    return await axios.get(`${window.FormConfiguration['API_URL']}/v1/entry/form/${data.formId}?size=${data.size}&page=${data.page}${sort}${searchValue}${startDate}${endDate}`);
}

export const deleteEntry = async (id) => {

    return await axios.delete(`${window.FormConfiguration['API_URL']}/v1/entry/${id}`);
}

