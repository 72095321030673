import { Empty } from 'antd'
import React from 'react'

const EmptyWithMessage = ({title,subtitle}) => {
    return (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>
                        <strong>{title ?? `No submissions added yet in this date range`}</strong>
                    </span>
                    <span>{subtitle ?? `We couldn't find any submissions, are you sure you published the form? Or try expanding the date range`}</span>
                </div>

            } />
    )
}

export default EmptyWithMessage