import axios from "axios";
const version = window.__version ?? "v1";
export const getFormHeader = async (data) => {
    const sort = data.sort ? "&sort=" + data.sort : ""
    return await axios.get(`${window.FormConfiguration['API_URL']}/${version}/forms?size=${data.size}&page=${data.page}${sort}`);
}

export const getFormById = async (id) => {
    return await axios.get(`${window.FormConfiguration['API_URL']}/${version}/forms/${id}`);
}

export const deleteFormHeader = async (id) => {

    return await axios.delete(`${window.FormConfiguration['API_URL']}/${version}/forms/${id}`);
}

export const createForm = async (data) => {
    return await axios.post(`${window.FormConfiguration['API_URL']}/${version}/forms`, data);
}

export const updateForm = async (data) => {
    return await axios.put(`${window.FormConfiguration['API_URL']}/${version}/forms`, data);
}