import { Button, Card, Col, Divider, Drawer, Form, Input, List, Row, Select, Space, Switch, InputNumber } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import FormDetailListContainer from './FormDetailListContainer';
import { INPUT_TYPES, MESSAGE_REQUIRED, COLUMN_TYPES } from '../../Utils/Constans';
const { Option } = Select;

const FormDetailContainer = ({ formDetailList, setFormDetailList }) => {
    const [drawerForm, setDrawerForm] = useState(false)
    const [selectedInputType, setSelectedInputType] = useState('')
    const [itemValue, setItemValue] = useState(null)
    const [textValue, setTextValue] = useState(null)
    const [orderValue, setOrderValue] = useState(0)

    const [dataItems, setDataItems] = useState([])
    const [validateStatusList, setValidateStatusList] = useState("")
    const [validateStatusHelp, setValidateStatusHelp] = useState("")
    const [selectedData, setSelectedData] = useState(null)
    const [selectedDataKey, setSelectedDataKey] = useState(null)
    const inpuType = INPUT_TYPES
    const [form] = Form.useForm();

    const columnTypes = COLUMN_TYPES
    const [selectedColumnType, setSelectedColumnType] = useState('')
    const onChangeColumnType = (value) => { setSelectedColumnType(value) }

    const onChangeInpuType = (value) => { setSelectedInputType(value) }
    const showInputValidation = selectedInputType === 'textfield'
    const showInputList = ['dropdown', 'checkboxmultiple', 'dropdownkeyvalue', 'checkboxmultiplekeyvalue'].includes(selectedInputType)
    const showInputKey = ['captcha'].includes(selectedInputType)

    const showDropdownkey = ['dropdownkeyvalue', 'checkboxmultiplekeyvalue'].includes(selectedInputType)

    useEffect(() => {
        setSelectedColumnType(selectedData?.columnType)
        setSelectedInputType(selectedData?.inputType)
        setSelectedDataKey(selectedData?.key)

        form.setFieldValue('mailChimpField', selectedData?.mailChimpField)
        form.setFieldValue('columnType', selectedData?.columnType)
        form.setFieldValue('inputType', selectedData?.inputType)
        form.setFieldValue('placeholder', selectedData?.placeholder)
        form.setFieldValue('regexValidation', selectedData?.regexValidation)
        form.setFieldValue('isMandatory', selectedData?.isMandatory)
        form.setFieldValue('messageMandatory', selectedData?.messageMandatory)
        form.setFieldValue('messageRegex', selectedData?.messageRegex)
        form.setFieldValue('keyAdditional', selectedData?.keyAdditional)
        form.setFieldValue('order', selectedData?.order)
        form.setFieldValue('description', selectedData?.description)

        if (['dropdown', 'checkboxmultiple', 'dropdownkeyvalue', 'checkboxmultiplekeyvalue'].includes(selectedData?.inputType)) {
            const arrData = JSON.parse(selectedData?.keyAdditional)
            setDataItems(arrData)
        } else setDataItems([])


    }, [selectedData, form])


    const onChangeItemValue = (value) => { setItemValue(value.target.value) }
    const onChangeOrderValue = (value) => { setOrderValue(value.target.value) }
    const onChangeTextValue = (value) => { setTextValue(value.target.value) }

    const onRemoveItem = (index) => {
        const data = [...dataItems];
        data.splice(index, 1)
        setDataItems(data)
    }
    const onSubmitForm = (values) => {
        if (['dropdown', 'checkboxmultiple', 'dropdownkeyvalue', 'checkboxmultiplekeyvalue'].includes(selectedInputType) && dataItems.length <= 0) {
            setValidateStatusList("error")
            setValidateStatusHelp("List of Items cannot be empty")
            return false
        } else {
            setValidateStatusList("")
            setValidateStatusHelp("")
        }


        if (showInputList) values['keyAdditional'] = JSON.stringify(dataItems)
        if (selectedDataKey) {
            values['key'] = selectedDataKey
            const filterData = formDetailList.filter(x => x.key === values['key']).pop()
            const indexData = formDetailList.findIndex(x => x.key === values['key'])
            var newdata = { ...filterData, ...values }
            formDetailList[indexData] = newdata;
            setFormDetailList([...formDetailList])
        }
        else {
            values['key'] = Math.random()
            setFormDetailList([...formDetailList, values])
        }
        setDataItems([])
        setDrawerForm(false)
        onCloseDrawer()
    };

    const onCloseDrawer = () => {
        setDrawerForm(false)
        setValidateStatusList("")
        setValidateStatusHelp("")
        form.resetFields()
        setSelectedInputType(null)
        setSelectedData(null)
    }

    const onAddItemList = () => {

        if (itemValue) {
            let keyItem = itemValue
            if (textValue) {
                keyItem += ";" + textValue
            }
            keyItem += ";" + (orderValue ?? 0)
            setDataItems([...dataItems, keyItem]);
            setItemValue('')
            setTextValue('')
            setOrderValue(0)
        }
    }

    const onSelectedDataDetail = (record) => {
        setSelectedData(record)
    }
    return (
        <>
            <Card size="small" title="Form Detail" style={{ marginTop: "10px" }} extra={
                <Button type="primary" onClick={() => { setSelectedData(null); setDrawerForm(true) }}>
                    New
                </Button>
            }>
                <Row>
                    <Col span={24}>
                        <FormDetailListContainer
                            setDrawerForm={setDrawerForm}
                            formDetailList={formDetailList}
                            setFormDetailList={setFormDetailList}
                            onSelectedDataDetail={onSelectedDataDetail} />
                    </Col>
                </Row>
            </Card >
            <Drawer size='large' title="Form Detail" placement="right" className='dynamic-form-drawer'
                onClose={onCloseDrawer} visible={drawerForm}
                extra={
                    <Space>
                        <Button form='formDetail' type="primary" htmlType='submit'>Save</Button>
                    </Space>
                }>
                <Form id='formDetail' labelCol={{ span: 4, }} wrapperCol={{ span: 19, }} onFinish={onSubmitForm}
                    initialValues={
                        { IsMandatory: false }
                    } form={form}>
                    <Form.Item label="Type" name="inputType" rules={[
                        {
                            required: true,
                            message: MESSAGE_REQUIRED,
                        },
                    ]}>
                        <Select onChange={onChangeInpuType}>
                            {
                                (inpuType).map((item) => {
                                    return (
                                        <Option key={item.key} value={item.key}>{item.value}</Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Divider />
                    {
                        selectedInputType && selectedInputType !== 'checkbox' &&
                        <>
                            <Form.Item name="order" label="Order" rules={[
                                {
                                    required: true,
                                    message: MESSAGE_REQUIRED,
                                },
                            ]}>
                                <InputNumber type={"number"} defaultValue={0} />
                            </Form.Item>

                            <Form.Item name="placeholder" label="Placeholder" rules={[
                                {
                                    required: true,
                                    message: MESSAGE_REQUIRED,
                                },
                            ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="description" label="Description" rules={[
                                {
                                    required: false,
                                    message: MESSAGE_REQUIRED,
                                },
                            ]}>
                                <Input.TextArea rows={7} />
                            </Form.Item>
                            {selectedInputType && selectedInputType !== 'hidden' &&
                                <>
                                    <Form.Item label="Column" name="columnType" rules={[
                                        {
                                            required: true,
                                            message: MESSAGE_REQUIRED,
                                        },
                                    ]}>
                                        <Select onChange={onChangeColumnType}>
                                            {
                                                (columnTypes).map((item) => {
                                                    return (
                                                        <Option key={item.key} value={item.key}>{item.value}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name="isMandatory" label="Mandatory" valuePropName="checked">
                                        <Switch />
                                    </Form.Item>
                                    <Form.Item name="messageMandatory" label="Message Mandatory">
                                        <Input.TextArea />
                                    </Form.Item>
                                    <Form.Item name="mailChimpField" label="MailChimp Field">
                                        <Input />
                                    </Form.Item>
                                </>
                            }


                            {showInputValidation &&
                                <>
                                    <Form.Item name="regexValidation" label="Input Validation">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="messageRegex" label="Message Validation">
                                        <Input.TextArea />
                                    </Form.Item>
                                </>

                            }
                            {showInputList && !showDropdownkey &&
                                <>
                                    <Divider />
                                    <Form.Item label="Order" validateStatus={validateStatusList} help={validateStatusHelp}>
                                        <Input.Group compact>
                                            <Input style={{ width: '90%' }} defaultValue={0} value={orderValue} type='number' onChange={onChangeOrderValue} />
                                        </Input.Group>
                                    </Form.Item>
                                    <Form.Item label="Display" validateStatus={validateStatusList} help={validateStatusHelp}>
                                        <Input.Group compact>
                                            <Input style={{ width: '90%' }} value={itemValue} onChange={onChangeItemValue} />
                                            <Button onClick={() => onAddItemList()} icon={<PlusOutlined />} />
                                        </Input.Group>
                                    </Form.Item>

                                    <List
                                        size="small"
                                        header={<div>List of Items</div>}
                                        bordered
                                        dataSource={dataItems}
                                        renderItem={(item, index) => {
                                            const arrSplit = item?.split(";")
                                            return (
                                                <List.Item actions={[<Button onClick={() => onRemoveItem(index)} icon={<DeleteOutlined />} />]}>
                                                    <div style={{ display: 'flex', justifyContent: "space-between", flex: 1 }}>
                                                        <span>Display = {arrSplit[0] || ''}</span>
                                                        <span>Order = {arrSplit[1] || 0}</span>
                                                    </div>
                                                </List.Item>
                                            )
                                        }}
                                    />
                                </>
                            }

                            {showInputList && showDropdownkey &&
                                <>
                                    <Divider />
                                    <Form.Item label="Order" validateStatus={validateStatusList} help={validateStatusHelp}>
                                        <Input.Group compact>
                                            <Input style={{ width: '90%' }} defaultValue={0} value={orderValue} type='number' onChange={onChangeOrderValue} />
                                        </Input.Group>
                                    </Form.Item>
                                    <Form.Item label="Value" validateStatus={validateStatusList} help={validateStatusHelp}>
                                        <Input.Group compact>
                                            <Input style={{ width: '90%' }} value={itemValue} onChange={onChangeItemValue} />

                                        </Input.Group>
                                    </Form.Item>
                                    <Form.Item label="Display" validateStatus={validateStatusList} help={validateStatusHelp}>
                                        <Input.Group compact>

                                            <Input style={{ width: '90%' }} value={textValue} onChange={onChangeTextValue} />
                                            <Button onClick={() => onAddItemList()} icon={<PlusOutlined />} />
                                        </Input.Group>
                                    </Form.Item>
                                    <List
                                        size="small"
                                        header={<div>List of Items</div>}
                                        bordered
                                        dataSource={dataItems}
                                        renderItem={(item, index) => {
                                            const arrSplit = item?.split(";")
                                            return (
                                                <List.Item actions={[<Button onClick={() => onRemoveItem(index)} icon={<DeleteOutlined />} />]}>
                                                    <div style={{ display: 'flex', justifyContent: "space-between", flex: 1 }}>
                                                        <span>Value = {arrSplit[0] || ''}</span>
                                                        <span>Display = {arrSplit[1] || ''}</span>
                                                        <span>Order = {arrSplit[2] || 0}</span>
                                                    </div>
                                                </List.Item>
                                            )
                                        }}
                                    />
                                </>
                            }

                            {showInputKey &&
                                <Form.Item name="keyAdditional" label="Key" rules={[
                                    {
                                        required: true,
                                        message: MESSAGE_REQUIRED,
                                    },
                                ]}>
                                    <Input />
                                </Form.Item>
                            }
                        </>
                    }

                </Form>
            </Drawer>
        </>
    )
}

export default FormDetailContainer